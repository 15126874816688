/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      media="illu_product-dashboard.png"
      title="Suivez vos activités en temps réel et prenez les meilleures décisions"
      name="Le-tableau-de-bord"
      label="tableau de bord"
      description="Où que vous soyez, suivez vos performances en temps réel depuis votre tableau de bord disponible sur ordinateur ou mobile."
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_product-dashboard_performanceanalysis.png',
              title: 'Analyse des performances',
              content: 'Obtenez des rapports détaillés de vos performances et activités',
            },
            {
              media: 'icon_product-dashboard_products.png',
              title: 'Gestion des stocks',
              content: 'Suivez vos stocks, commandez vos produits au moment opportun',
            },
            {
              media: 'icon_product-dashboard_clientmonitoring.png',
              title: 'Gestion des clients',
              content: 'Suivez vos ventes aux particuliers et aux professionnels ',
            },
            {
              media: 'icon_product-pointofsales_activities.png',
              title: 'Suivi comptable',
              content: 'Exportez toutes vos données comptables en un clic',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'mujeres',
              media: 'screenshot_dashboard_performanceanalysis.png',
              alt: 'Analyse des performances',
              label: 'Analyse des performances',
              title:
                "Accédez à vos chiffres-clés et prenez les meilleures décisions en un clin d'oeil",
              content:
                "Suivez en temps réel et à distance vos ventes, votre chiffre d'affaires du jour, du mois en cours ou de l'année, vos factures impayées ou encore le montant du panier moyen de votre clientèle. Analysez les comportements de vos clients, les flux de visite en boutique et l'influence du programme de fidélité sur vos ventes. Identifiez également vos produits phares, la valeur de vos stocks et bien plus encore.",
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_dashboard_stock.png',
              alt: 'Gestion des stocks',
              label: 'Gestion des stocks',
              title:
                'Gardez toujours vos stocks à jour, gérez vos commandes et livraisons simplement',
              content:
                "Gérez vos produits et leurs niveaux de stock depuis le tableau de bord en ligne. Soyez alerté quand un produit vient à manquer et créez alors vos commandes fournisseurs. Sur l'interface de commande, ajoutez vos produits, des frais de transport, des taxes, des remises ou gratuités selon vos tarifs négociés. Réceptionnez ensuite vos produits livrés en quelques clics et suivez vos paiements aux fournisseurs. Retrouvez l'historique des commandes passées, en cours et à venir.",
            },
          ],
        },
        {
          name: 'informationInsert',
          items: [
            {
              media: 'everywhere',
              title: 'Partout et à tout moment',
              content:
                "Vous pouvez accéder à vos chiffres-clés, vos activités et vos documents commerciaux tout le temps et partout depuis votre ordinateur, votre caisse tactile sur iPad et votre mobile. C'est un vrai gain de temps au quotidien.",
            },
            {
              media: 'multishop',
              title: 'Idéal pour un usage multi-boutique',
              content:
                "Que vous ayez une caisse ou plusieurs points de vente, toutes vos informations sont centralisées. Le tableau de bord vous permet de naviguer d'une cave à une autre en un clic. Vous avez un oeil sur tout, instantanément !",
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'quarantine',
              media: 'screenshot_dashboard_clientmonitoring.png',
              alt: 'gestion des clients',
              label: 'Gestion des clients',
              title:
                'Suivez les achats de vos clients particuliers et professionnels, contactez-les dès que nécessaire ',
              content:
                "Vous avez besoin d'obtenir rapidement des informations sur l'un de vos clients ? Depuis le répertoire en ligne, retrouvez toutes les données nécessaires pour le contacter ou suivre ses achats passés dans la boutique ou à distance. Analysez ses habitudes d'achat et vendez-lui des produits qui lui plairont.",
              feedback: {
                profile: 'photo_product-dashboard_cavedesverresluisants.jpg',
                name: 'Bertrand Senut',
                company: 'La Cave Des Verres Luisants',
                content:
                  'Nos clients apprécient qu’on leur communique leurs tickets par mail, ils sont facilement partants pour nous donner leur adresse mail et être inscrits dans notre base client.',
              },
            },
            {
              decoration: 'alwadood',
              media: 'screenshot_dashboard_accountmonitoring.png',
              alt: 'gestion des clients',
              label: 'Suivi comptable',
              title:
                'Gardez une comptabilité à jour, exportez vos documents comptables instantanément',
              content:
                'Suivez attentivement votre activité de commerçant. Accédez à tous vos documents de vente depuis le tableau de bord : tickets de caisse, factures et journaux de ventes. Tout est exportable en un clic, rien de plus simple pour les transmettre à votre comptable chaque mois !',
            },
          ],
        },
      ]}
    />
  )
}
